/**
 * Created by osirvent on 08/04/2016.
 * Modified by aguerrero on 14/04/2016.
 */
angular
    .module('annexaApp')
    .controller('BooksController',['$rootScope', '$scope', '$state', 'HeaderService', 'Language', 'ErrorFactory', 'RouteStateFactory', 'TableFilterFactory',
        function ($rootScope, $scope, $state, HeaderService, Language, ErrorFactory, RouteStateFactory, TableFilterFactory) {
        $scope.languageColumn = Language.getActiveColumn();
        var vm = this;

    }])
    .controller('BooksListController',['$rootScope', '$scope', '$state', '$filter', '$stateParams', 'HeaderService', '$timeout', 'RouteStateFactory', 'TableFilterFactory', 'RestService', 'BooksModals', 'AnnexaFormlyFactory', 'BooksFactory', 'AnnexaPermissionsFactory', 'DccumentsFactory', 'DialogsFactory', 'GlobalDataFactory',
        function ($rootScope, $scope, $state, $filter, $stateParams, HeaderService, $timeout, RouteStateFactory, TableFilterFactory, RestService, BooksModals, AnnexaFormlyFactory, BooksFactory, AnnexaPermissionsFactory, DccumentsFactory, DialogsFactory, GlobalDataFactory) {
    	$scope.$on('annexaAdminBaseReloadTable', function(event, args) {
            $scope.tableDefinition.reloadInternalData(false);
        });
    	$scope.bookStates = angular.copy(BooksFactory.bookStates);
    	
    	$scope.tfilter = [
    		{ id: 'name', type: 'text', order: 0, label: 'global.literals.name'},
        	{ id: 'bookType', type: 'select', order: 1, label: 'global.books.list.bookType', dataType: 'LOCAL', data: new SortedArray(BooksFactory.bookTypes, $scope.languageColumn).sort(), addAll: true, nameProperty: $scope.languageColumn},
            { id: 'state', type: 'select', order: 2, label: 'global.literals.state', dataType: 'LOCAL', data: new SortedArray(BooksFactory.bookStates, 'name').sort(), addAll: true, nameProperty: 'name'}
        ];

    	var routeState = RouteStateFactory.getRouteState($state.current);
        if(routeState) {
            $scope.tfilter = routeState.state;
        }
        $scope.getFilterCall = getFilterCall;
        $scope.getFilterCallAux = getFilterCallAux;
        $scope.submit = submit;

        function submit(val) {
            $scope.$broadcast('filterData', {});
        };

        HeaderService.onChangeState($scope, onChangeState);
        HeaderService.changeState($state.current);
        var dossierRender = function(data, type, full, meta) {
            var content = '';
            if(data && data.dossierNumber) {
            	content = data.dossierNumber;
            }
            return content;
        }
        
        var statusRender = function(data, type, full, meta) {
            var content = '';
              
            if(BooksFactory.bookStates && BooksFactory.bookStates.length > 0) {
                var state = $linq(BooksFactory.bookStates).singleOrDefault(undefined, "x => x.id == '" + data + "'");
                if(state) {
                    content += '<div class="text-center">';
                    content += '    <span class="fa-stack" title=" ' + $filter('translate')(state.name) + '">';
                    content += '        <i class="fa fa-' + state.icon + ' ' + state.style + ' text-lg fa-stack-1x"></i>';
                    content += '    </span>';
                    content += '</div>';
                }
            }

            return content;
        }
        
        $scope.columnsAux = [
        	{id: 'state', width: '1%', title: $filter('translate')('global.literals.state'), render: statusRender, sortable: false},
        	{id: 'name', column: new TextColumn($filter, 'global.literals.name')},
        	{id: 'bookType.'+$scope.languageColumn, column: new TextColumn($filter,'global.books.list.bookType')},
            {id: 'dossier', title: $filter('translate')('global.literals.dossier'), render: dossierRender}
        ];

        var datatableSettings = $rootScope.app.configuration.datatables_settings;
        $scope.columnsListOfQuery = angular.copy($scope.columnsAux);
        $scope.columnsListOfQuery.push({id: 'id', width: '8%', column: new ActionsColumn($filter('translate')('global.literals.actions'), 
        		new ActionButton('global.literals.see', 'annexa.books.book({ id: \'[data]\'})', 'fa-eye', true),
                
        		[ 
        			new ActionButton('global.literals.remove','removeElement([data])', 'fa-trash', undefined, function(data, type, full, meta){
        				if(full && full.bookType && BooksFactory.havePermissionBookType(full.bookType, 'update') && full.state == 'DRAFT'){
        					return true;
        				}else{
        					return false;
        				}
        			})
        		]),sortable: false 
        });
        $scope.columnsListOfQuery = getDatatableColumnsSettings(datatableSettings, 'datatable_execute_query', $scope.columnsListOfQuery);

        var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_execute_query', $scope.columnsListOfQuery);
        $scope.tableOrderProperties = {sortName: 'id', sort: [[0, 'desc']]};
        if (orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index > 0) {
            $scope.tableOrderProperties = {
                sortName: orderProperties.name,
                sort: [[orderProperties.index, orderProperties.direction]]
            };
        }

        $scope.tableDefinition = {
            id: 'tableListOfBooks',
            origin: 'books',
            objectType: 'Book',
            sortName: $scope.tableOrderProperties.sortName,
            sort: $scope.tableOrderProperties.sort,
            filterCall: $scope.getFilterCall(),
            filterCallAux: $scope.getFilterCallAux(),
            filterCallFunc: $scope.getFilterCall,
            filterCallAuxFunc: $scope.getFilterCallAux,
            columns: angular.copy($scope.columnsListOfQuery),
            containerScope: $scope
        }


        function getFilterCall() {
            var filterCall = TableFilterFactory.getFilterCall($scope.tfilter);
            if(filterCall && filterCall.state && filterCall.state.id){
            	filterCall.state = filterCall.state.id;
            }
            return filterCall;
        };


        function getFilterCallAux() {
            var filterCallAux = TableFilterFactory.getFilterCallAux($scope.tfilter);
            filterCallAux.loggedUser = $rootScope.LoggedUser.id;
            filterCallAux.langColumn = $scope.languageColumn;
            return filterCallAux;
        };

        function onChangeState(message) {
            $rootScope.subHeadButtons = [
            	new HeadButtonSearch('#tableFilter'),
            	new HeadButton('btn primary breadDivButton m-r-sm', undefined, '#dataTableExecuteBooks', 'fa fa-cog', 'global.books.list.executeJobMessage', undefined, 'executeJob').setPermissions('execute_book_job')
            ];
            if(BooksFactory.bookTypesToUpdate && BooksFactory.bookTypesToUpdate.length > 0){
            	$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-r-sm', undefined, '#dataTableExecuteBooks', 'fa fa-plus', 'global.books.list.new', undefined, 'newElement'));
            }
            $rootScope.subHeadTabs = [];
        };
        
        $scope.removeElement = function(id) {
        	DialogsFactory.confirm('global.books.list.deleteMessage', 'global.books.list.deleteMessageConfirm')
            .then(function (data) {
	            RestService.delete('./api/books/' + id).then(function (data) {
	        		$scope.tableDefinition.reloadInternalData(true, true);
	            }).catch(function (error) {
	            	DialogsFactory.error($filter('translate')('global.books.list.errorDelete'));
	            });
           }).catch(function (data) {
	               //Empty
	       });
        };
        
        $scope.executeJob = function(id) {
        	DialogsFactory.confirm('global.books.list.executeJobMessage', 'global.books.list.executeJobMessageConfirm')
            .then(function (data) {
            	BooksFactory.executeBookJob().then(function (data) {
            		if(data){
            			if(data == 'JOB_FIRED' || data == 'JOB_CURRENTLY_RUNNING'){
            				DialogsFactory.notify($filter('translate')('global.books.list.executeJobFired'));
            			}else {
            				DialogsFactory.notify($filter('translate')('global.books.list.errorExecuteJob'));
            			}
            		}else{
            			DialogsFactory.error($filter('translate')('global.books.list.errorExecuteJob'));
            		}
	            }).catch(function (error) {
	            	DialogsFactory.error($filter('translate')('global.books.list.errorExecuteJob'));
	            });
           }).catch(function (data) {
	               //Empty
	       });
        };
        
        $scope.newElement = function() {
            var modal = angular.copy(BooksModals.bookNew);
            modal.wizard.steps[0].annexaFormly.model = {};
            modal.wizard.steps[1].annexaFormly.model = {};
            modal.wizard.modal = modal;
            modal.wizard.module = 'BOOKS';
        	modal.wizard.languageColumn = $scope.languageColumn;
            modal.wizard.documentService = DccumentsFactory;
            modal.wizard.listOfBookTypes = BooksFactory.bookTypesToUpdate;
            modal.wizard.getDocumentType = function(id){
            	if(id && GlobalDataFactory && GlobalDataFactory.documentTypes){
            		return $linq(GlobalDataFactory.documentTypes).firstOrDefault(undefined, "x => x.id == "+id);
            	}
            	return null;
            }
            AnnexaFormlyFactory.showModal('modalNewBook', modal, $scope.saveNewBook, false, true);
        };

        $scope.saveNewBook = function(){
        	var self = this;
            
        	if (this.annexaFormly.form.$valid) {
                var bookModel = angular.copy(self.wizard.steps[0].annexaFormly.model);
                var documentModel = angular.copy(self.wizard.steps[1].annexaFormly.model);

                DccumentsFactory.createNewDocument(documentModel.modal_body, self.wizard.module)
                    .then(function(dataDocument) {
                    	if(dataDocument){
                    		bookModel.bookType = ((bookModel.bookType)?{id:bookModel.bookType}:undefined);
                    		bookModel.indexDocument = dataDocument;
	                        RestService.insert('./api/books', bookModel).then(function(data) {
	                        	$scope.tableDefinition.reloadInternalData(true, true);
	                        	self.close();
	                        }).catch(function(error){
	                        	self.alerts.push(error);
	                        });
                    	}else{
                    		self.alerts.push({ msg: $filter('translate')('global.errors.errorCreateDocument') });
                    	}
                    }).catch(function(error) {
                        self.alerts.push(error);
                });
            }else{
            	modal.alerts.push({ msg: $filter('translate')('global.errors.errorCreateDocument') });
            }
        }

    }])
    .controller('BooksViewController',['$rootScope', '$scope', '$state', 'HeaderService', 'BooksFactory',
    function ($rootScope, $scope, $state, HeaderService, BooksFactory) {
    	$scope.book = BooksFactory.book;

    	HeaderService.onChangeState($scope, function(message) {
            if(message.state.name == 'annexa.books.book' && BooksFactory.book) {
                $rootScope.subHeadButtons = [];
                if(BooksFactory.havePermissionBookType(BooksFactory.book.bookType, 'update')){
	                if(_.contains(['CLOSING_REQUEST', 'CLOSED', 'PENDING_SIGN', 'REJECTED', 'ERROR'], BooksFactory.book.state)){
	                	$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-r-sm', undefined, '#edit-book', undefined, 'global.books.view.reopenBook', undefined, 'reopenBook'));
	                }
	                if(_.contains(['DRAFT'], BooksFactory.book.state)){
						$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-r-sm', undefined, '#edit-book', undefined, 'global.books.view.closingRequestBook', undefined, 'closingRequestBook'));
					}
	                if(_.contains(['CLOSED'], BooksFactory.book.state)){
						$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-r-sm', undefined, '#edit-book', undefined, 'global.documents.datatable.enviarFirmar', undefined, 'sendToSignBook'));
					}
                }
            }
        });

        HeaderService.changeState($state.current, true);
    	
    }]);