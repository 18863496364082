/**
 * Created by osirvent on 08/04/2016.
 */
angular
    .module('annexaApp')
    .config(['$stateProvider', function ($stateProvider) {
        $stateProvider

            .state('annexa.books', {
                url: '/books',
                redirectTo: 'annexa.books.list',
                views: {
                    "": {
                        controller: 'BooksController',
                        templateUrl: './views/layout/books.html'
                    }
                },
                data: {
                    title: 'global.books.toptitle',
                    displayName: 'global.books.toptitle',
                    authenticate: true,
                    permissions: {
                        only: ['generate_book']
                    }
                },
                resolve: {
                	initializeList: ['BooksFactory', function (BooksFactory) {
                		return BooksFactory.initializeLists();
                    }]
                }
            })
            .state('annexa.books.list', {
                url: '/list',
                views: {
                    "mainbooks@annexa.books": {
                        controller: 'BooksListController',
                        templateUrl: './views/layout/booksList.html'
                    }
                },
                data: {
                    title: 'global.books.toptitle',
                    displayName: 'global.books.list.toptitle',
                    authenticate: true,
                    permissions: {
                        only: 'generate_book'
                    }
                },
                resolve: {

                }
            })
            .state('annexa.books.book', {
                url: '/book/:id',
                views: {
                    "mainbooks@annexa.books": {
                        controller: 'BooksViewController',
                        templateUrl: './views/layout/book.html'
                    }
                },
                data: {
                    title: 'global.books.toptitle',
                    displayName: 'global.books.view.toptitle',
                    authenticate: true,
                    permissions: {
                        only: 'generate_book'
                    }
                },
                resolve: {
                    getBook: ['BooksFactory', '$stateParams', function (BooksFactory, $stateParams) {
                    	return BooksFactory.getBook($stateParams.id);
                    }],
                    getSecList: ['SecFactory', function (SecFactory) {
                    	return SecFactory.loadBookSec();
                    }]
                }
            });
    }]);