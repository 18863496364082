angular
    .module('annexaApp')
    .factory('BooksFactory',['$q', '$http','Language', 'HelperService', '$filter', '$rootScope', 'RestService', 'DccumentsFactory', function($q, $http, Language, HelperService, $filter, $rootScope, RestService, DccumentsFactory) {
        var factory = {};
        factory.book = undefined;
        factory.bookTypes = [];
        factory.bookTypesToUpdate = [];
        factory.bookStates = [
        	{id:'DRAFT', name:$filter('translate')('global.books.states.DRAFT'), icon: 'circle-o', style: 'text-blue'},
        	{id:'CLOSING_REQUEST', name:$filter('translate')('global.books.states.CLOSING_REQUEST'), icon: 'adjust', style: 'text-blue'},
        	{id:'CLOSED', name:$filter('translate')('global.books.states.CLOSED'), icon: 'circle', style: 'text-blue'},
        	{id:'PENDING_SIGN', name:$filter('translate')('global.books.states.PENDING_SIGN'), icon: 'dot-circle-o', style: 'text-success'},
        	{id:'GENERATE_ENI', name:$filter('translate')('global.books.states.GENERATE_ENI'), icon: 'circle', style: 'text-success'},
        	{id:'SIGNED', name:$filter('translate')('global.books.states.SIGNED'), icon: 'check-circle', style: 'text-success'},
        	{id:'REJECTED', name:$filter('translate')('global.books.states.REJECTED'), icon: 'minus-circle', style: 'text-danger'},
        	{id:'ERROR', name:$filter('translate')('global.books.states.ERROR'), icon: 'exclamation-circle', style: 'text-danger'}
        ];
        factory.bookElements = [
        	{id:'REGISTER', name:$filter('translate')('global.books.elements.REGISTER')},
        	{id:'DOCUMENT', name:$filter('translate')('global.books.elements.DOCUMENT')},
        	{id:'SESSION', name:$filter('translate')('global.books.elements.SESSION')},
        	{id:'DOSSIER_DOCUMENT', name:$filter('translate')('global.books.elements.DOSSIER_DOCUMENT')},
        	{id:'DOSSIER', name:$filter('translate')('global.books.elements.DOSSIER')}
        ];
        factory.getBook = function(id){
        	factory.book = undefined;
            return RestService.findOne('Book', id).then(function (data) {
                factory.book = JSOG.decode(data.data);
            }).catch(function (error) {
                console.error(error);
            });
        }
        
        factory.initializeLists = function(){
        	var promises = [];

            promises.push(RestService.findAll('BookType'));
            promises.push(DccumentsFactory.getDocumentLists());
            
            return $q.all(promises)
                .then(function(data) {
                	if(data && data[0].data){
	            		var bookTypesAux = JSOG.decode(data[0].data);
	            		factory.bookTypes = $linq(bookTypesAux).where(function(x){
	            			return factory.havePermissionBookType(x);
	            		}).toArray();
	            		factory.bookTypesToUpdate = $linq(bookTypesAux).where(function(x){
	            			return factory.havePermissionBookType(x, 'update');
	            		}).toArray();
	            	}
                }).catch(function(error) {
                    factory.bookTypes = [];
                    factory.bookTypesToUpdate = [];
                });
        }

        factory.havePermissionBookType = function(bookType, permissionType){
        	var loggedUser = $rootScope.LoggedUser;
        	if(permissionType){
        		var intersect = [];
        		if(permissionType == 'update'){
        			if(bookType && bookType.bookTypeprocessProfiles){
            			if(bookType.bookTypeprocessProfiles && loggedUser && loggedUser.userProfiles){
                			intersect = $linq(bookType.bookTypeprocessProfiles).intersect(loggedUser.userProfiles, "(x,y) => x.profile && y.profile && x.profile.id == y.profile.id").toArray(); 
                			if(intersect && intersect.length > 0){
                				return true;
                			}
            			}
        			}
        		}else if(permissionType == 'read'){
        			if(bookType && bookType.bookTypeViewProfiles){
            			if(bookType.bookTypeViewProfiles && loggedUser && loggedUser.userProfiles){
                			intersect = $linq(bookType.bookTypeViewProfiles).intersect(loggedUser.userProfiles, "(x,y) => x.profile && y.profile && x.profile.id == y.profile.id").toArray(); 
                			if(intersect && intersect.length > 0){
                				return true;
                			}
            			}
        			}
        		}
        		return false;
        	}else if(bookType){
        		if(factory.havePermissionBookType(bookType, 'update')){
        			return true;
        		}else{
        			return factory.havePermissionBookType(bookType, 'read');
        		}
        	}else{
        		return false;
        	}
        	
        }

        factory.addElementsToBook = function(bookId, elemetsToAdd){
        	var deferrend = $q.defer();
            $http({
                method: 'POST',
                url: './api/books/addElements/'+bookId,
                data: JSOG.encode(elemetsToAdd)
            }).success(function (data, status) {
                deferrend.resolve(JSOG.decode(data.data));
            }).error(function (msg, code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        }
        
        factory.deleteElementToBook = function(bookId, bookElementId){
        	var deferrend = $q.defer();
            $http({
                method: 'DELETE',
                url: './api/books/removeElement/'+bookId+'/'+bookElementId
            }).success(function (data, status) {
                deferrend.resolve(JSOG.decode(data.data));
            }).error(function (msg, code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        }
        
        factory.changeState = function(bookId, bookState){
        	var deferrend = $q.defer();
            $http({
                method: 'PUT',
                url: './api/books/changeState/'+bookId+'/'+bookState
            }).success(function (data, status) {
                deferrend.resolve(JSOG.decode(data));
            }).error(function (msg, code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        }
        
        factory.executeBookJob = function(){
        	var deferrend = $q.defer();
            $http({
                method: 'PUT',
                url: './api/books/executeBookJob'
            }).success(function (data, status) {
                deferrend.resolve(JSOG.decode(data));
            }).error(function (msg, code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        }
        return factory;
    }]);